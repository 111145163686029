<template>
	<div>
		<a-spin :spinning="loading" tip="logining..">
			<div style="height: 100vh; width: 100%;"></div>
		</a-spin>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				loading:false
			}
		},
		created(){
			// let shop_token =  this.$route.query.shop_token;
			let shop_token = '3df7/OcNSP4PE2kGUJCaIg5oVpfLq0mwD/njxMrupt87kdU1DpDXaITEZFMg/KxDD7ya+vHBnv9EwMM3rmyh7oF2n0mcPA'
			console.log(shop_token);
			if(!shop_token){
				location.href = process.env.VUE_APP_ACCOUNT_URL+'/mendian';
			}else{
				
				this.$http.papi('platform/authLogin',{
					token:shop_token
				}).then(res=>{
					console.log(res);
					localStorage.setItem('auto-token',shop_token);
					this.$router.push('/index');
				}).catch(res=>{
					
				});	
			}
		},
		methods:{
			
		}
	}
</script>

<style>
	
</style>